import { useEffect, useState } from "react";
import { Card, message } from "antd";
import { Upload, DatePicker, Col, Drawer, Row, Input, Spin } from "antd";

import { upload_icon, delete_icon } from "../../../assets/icons/icons";
import { DownloadOutlined } from "@ant-design/icons";
import { ThemeButton, ThemeInput } from "../../../components/components";
import { uploadImage } from "../../../helpers/helpers";
import {
  useAddBookMutation,
  useGetBookQuery,
  useUpdateBookMutation,
} from "../../../services/book";
const { Dragger } = Upload;

const EventBooklet = ({ eventID, eventName, isUser }) => {
  const [formData, setFormData] = useState({
    title: "",
    thumbnail: "",
    pdf: "",
    bookId: null,
  });

  const { data, isLoading } = useGetBookQuery({ event_id: eventID });
  const [addBook, { isLoading: isLoadingAddBook }] = useAddBookMutation();
  const [updateBook, { isLoading: isLoadingUpdateBook }] =
    useUpdateBookMutation();

  const [uploading, setUploading] = useState(false);
  const [uploadingPDF, setUploadingPDF] = useState(false);

  useEffect(() => {
    if (data?.book) {
      setFormData({
        title: data?.book?.title,
        thumbnail: data?.book?.thumbnail,
        pdf: data?.book?.pdf,
        bookId: data?.book?._id,
      });
    }
  }, [isLoading]);

  const handleImage = async (info, type) => {
    console.log("info, type->", info, type);
    if (type === "thumb") {
      setUploading(true);
    } else {
      setUploadingPDF(true);
    }
    const { status } = info.file;
    if (status !== "uploading") {
      const res = await uploadImage(info.file.originFileObj);
      if (type === "thumb") {
        setUploading(false);
        setFormData({
          ...formData,
          thumbnail: res,
        });
      } else {
        setUploadingPDF(false);
        setFormData({
          ...formData,
          pdf: res,
        });
      }
    }
  };

  const handleOnClickAddBook = async () => {
    try {
      if (!formData?.bookId) {
        const bookIndo = {
          event: eventID,
          ...formData,
        };
        delete bookIndo.bookId;
        const pushedBook = await addBook(bookIndo);
        console.log("pushedBook->", pushedBook);
        message.success(pushedBook?.data?.message);
      } else {
        const bookIndo = {
          event: eventID,
          ...formData,
        };
        delete bookIndo.bookId;
        const updateBookRes = await updateBook({
          event_id: eventID,
          body: bookIndo,
        });
        console.log("updateBook->", updateBookRes);
        message.success(updateBookRes?.data?.message);
      }
    } catch (err) {
      message.error(err.message);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleDelete = (type) => {
    setFormData({ ...formData, [type]: "" });
  };

  return (
    <div className="rounded-lg p-6">

      <Row className="flex flex-col space-y-10">
        <Col className="flex justify-center items-center gap-5">
          <div className="flex-1">
            <ThemeInput
              label={"Booklet Title"}
              placeholder={"Title"}
              labelPrimary
              name={"title"}
              value={formData?.title}
              onChange={handleChange}
              disabled={isUser}
            />

          </div>
          <div className="mt-6">
            {!isUser && (
              <ThemeButton
                onClick={() => handleOnClickAddBook()}
                content={formData?.bookId ? "Update Booklet" : "Add Booklet"}
                loading={isLoadingAddBook || isLoadingUpdateBook}
              />
            )}
          </div>
        </Col>
        <Col className="flex gap-5">
          <div className="mb-4 flex-1">
            <p className={"font-semibold mb-1"}>{"Event Thumbnail"}</p>
            <div>
              {formData?.thumbnail ? (
                <>
                  <img
                    src={formData?.thumbnail}
                    alt=""
                    style={{ height: "400px" }}
                  />
                  {!isUser && (
                    <span
                      className="bg-themeGray-medium p-3 flex items-center justify-center w-12 rounded-l-2xl absolute right-2.5"
                      onClick={() => handleDelete("thumbnail")}
                    >
                      <img
                        src={delete_icon}
                        alt=""
                        className="!inline cursor-pointer"
                      />
                    </span>
                  )}
                </>
              ) : (
                !isUser && (
                  <Dragger
                    name="file"
                    multiple={false}
                    showUploadList={false}
                    onChange={(info) => handleImage(info, "thumb")}
                  >
                    {uploading ? (
                      <Spin />
                    ) : (
                      <>
                        <div className="flex justify-center mb-1">
                          <img src={upload_icon} alt="" width={40} />
                        </div>
                        <p className="text-primary font-semibold">Drag and drop your file</p>
                        <p className="text-primary font-semibold">OR</p>
                      </>
                    )}
                    <div className="flex justify-center mt-1">
                      <ThemeButton
                        disabled={uploading}
                        content={"Upload Image"}
                        className="!rounded-3xl"
                      />
                    </div>
                  </Dragger>
                )
              )}
            </div>
          </div>
          <div className="mb-4 flex-1">
            <p className={"font-semibold mb-1"}>{"Event PDF"}</p>
            <div>
              {formData?.pdf ? (
                <>
                  <a
                    className="bg-themeGray-medium p-3 flex items-center justify-center w-12 rounded-l-2xl absolute right-2.5 top-20 cursor-pointer"
                    target="_blank"
                    href={formData?.pdf}
                  >
                    <DownloadOutlined className="text-xl" />
                  </a>
                  <iframe
                    style={{ height: 400, width: 260 }}
                    src={formData.pdf}
                  ></iframe>
                  {!isUser && (
                    <span
                      className="bg-themeGray-medium p-3 flex items-center justify-center w-12 rounded-l-2xl absolute right-2.5"
                      onClick={() => handleDelete("pdf")}
                    >
                      <img
                        src={delete_icon}
                        alt=""
                        className="!inline cursor-pointer"
                      />
                    </span>
                  )}
                </>
              ) : (
                !isUser && (
                  <Dragger
                    name="file"
                    multiple={false}
                    showUploadList={false}
                    onChange={(info) => handleImage(info, "pdf")}
                  >
                    {uploadingPDF ? (
                      <Spin />
                    ) : (
                      <>
                        <div className="flex justify-center mb-1">
                          <img src={upload_icon} alt="" width={40} />
                        </div>
                        <p className="text-primary font-semibold">Drag and drop your file</p>
                        <p className="text-primary font-semibold">OR</p>
                        <div className="flex justify-center mt-1">
                          <ThemeButton
                            disabled={uploading || uploadingPDF}
                            content={"Browse File"}
                            className="!rounded-3xl"

                          />
                        </div>
                      </>
                    )}
                  </Dragger>
                )
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default EventBooklet;
