import { ThemeButton } from "../components";
import { edit_white_icon, delete_white_icon, presentation_icon, edit_icon, delete_icon } from "../../assets/icons/icons";
import { PlusCircleOutlined } from "@ant-design/icons";
import { showDeletePopup } from "../../helpers/helpers";
import { user } from "../../assets/images/images";
import { useState } from "react";
import { Col, Row } from "antd";

const SponsorsPresentationCard = ({
  sponsorDetail,
  onEdit,
  onDelete,
  isAdmin,
  isUser,
  isAdded,
  hall,
  onAddAgenda,
  event,
}) => {
  const [count, setCount] = useState(2);

  return (
    <div className="relative border border-primary rounded-xl p-8">
      <div
        className={` flex items-center static justify-center`}
      >
        <div className="flex flex-1 items-center justify-between border-b border-gray-200 pb-5">
          <div className="flex items-center">
            <img src={presentation_icon} alt="icon" width={28} height={28} />
            <p className="text-2xl text-[#e2b025] rounded-full  py-1 px-4 font-bold">
              Sponsor Presentation
            </p>
          </div>
          <div className="flex items-center gap-5">
            <div className="bg-primary px-5 py-3 rounded">
              <p className="text-2xl text-white font-semibold mt-1">{sponsorDetail?.start_time} - {sponsorDetail?.end_time}</p>
            </div>
            {isAdmin && !isAdded && (
              <div className="flex gap-5">
                <span
                  className="cursor-pointer w-8"
                  onClick={() => onEdit(sponsorDetail)}
                >
                  <img src={edit_icon} alt="" width={24} height={24} />
                </span>
                <span
                  className="cursor-pointer"
                  onClick={() => showDeletePopup(() => onDelete(sponsorDetail))}
                >
                  <img src={delete_icon} alt="" width={24} height={24} />
                </span>
              </div>
            )}

            {!isAdmin && isAdded !== -1 && (
              <div className="flex">
                <span
                  className="cursor-pointer"
                  onClick={() =>
                    showDeletePopup(() => onDelete(sponsorDetail.agenda_id))
                  }
                >
                  <img src={delete_icon} alt="" width={18} height={18} />
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      {(isAdmin || isAdded !== -1) && (
        <div
          className="maxH-[350px]"
          style={{ minHeight: 200 }}
        >
          <div className="flex justify-center items-center gap-5">
            <div className="flex flex-col items-center">
              <div className="w-[125px] h-[125px] rounded-full border-2 border-primary">
                <img
                  src={sponsorDetail?.speaker?.picture || user}
                  alt="profile"
                  className="w-full h-full rounded-full object-cover"
                />
              </div>
              <p className="text-xl text-primary font-semibold">Attendee</p>
            </div>
            <div>
              <img
                className={"w-[350px] h-[150px] object-contain"}
                src={sponsorDetail?.image}
                alt=""
              />
            </div>
          </div>
          <div className="grid grid-cols-3 items-center">
            <div className="">
              <p className="text-primary font-extrabold text-xl">
                {sponsorDetail?.speaker?.first_name}{" "}
                {sponsorDetail?.speaker?.last_name}
              </p>
              <p className="text-xl text-primary">
                {sponsorDetail?.speaker?.metadata?.delegate_details?.position ||
                  "----"}
              </p>
              <p className="text-primary text-sm font-semibold italic">
                {sponsorDetail?.speaker?.metadata?.delegate_details?.company ||
                  "----"}
              </p>
            </div>
            <div className="flex justify-center">
              <div className="w-[1.5px] h-[120px] bg-gray-200"></div>
            </div>
            <p className="text-primary text-[22px] font-semibold pb-1">
              {sponsorDetail?.title}
            </p>

          </div>

          {hall === "Hall B" && (
            <div className="absolute bottom-0 left-0 right-0 bg-gray-50 font-bold text-center text-[#e2b025] mt-4">
              <hr className="mb-2" />
              All sponsor presentations will be in Hall A
              <hr className="mb-1" />
            </div>
          )}

          <Row className="flex items-start mt-4">
            {sponsorDetail?.description.length > 0 &&
              sponsorDetail?.description?.slice(0, count).map((data, index) => {
                return (
                  <>
                    <Col span={1}>
                      <div
                        className={`${sponsorDetail?.hall === "Hall A"
                          ? "bg-primary"
                          : "bg-primary"
                          } bg-primary mt-2 rounded-full h-2 w-2`}
                      ></div>
                    </Col>
                    <Col span={23} className="mb-3">
                      <p className="font-medium">{data}</p>
                    </Col>
                  </>
                );
              })}
          </Row>
          {sponsorDetail?.description?.length > 2 && (
            <Row className="flex ">
              <Col
                onClick={() => {
                  if (sponsorDetail?.description?.length === count) {
                    setCount(2);
                  } else {
                    setCount(sponsorDetail?.description.length);
                  }
                }}
                span={24}
              >
                <div
                  className={`${sponsorDetail?.hall === "Hall A"
                    ? "text-primary"
                    : "text-[#e2b025]"
                    } font-bold	cursor-pointer text-right relative bottom-8`}
                >
                  {sponsorDetail?.description?.length === count
                    ? "Show less"
                    : "Show more"}
                </div>
              </Col>
            </Row>
          )}
        </div>
      )}

      {isUser && isAdded === -1 && (
        <div
          className="h-[350px] bg-white p-4 shadow-md shadow-gray-300 flex justify-center items-center"
          style={{ minHeight: 200 }}
        >
          <ThemeButton
            onClick={() => onAddAgenda(sponsorDetail)}
            content={
              <div>
                <PlusCircleOutlined className="mr-4" />
                <span>Add agenda</span>
              </div>
            }
            className="w-46 text-lg !h-12 mt-4"
          />
        </div>
      )}
    </div>
  );
};

export default SponsorsPresentationCard;
