import { Row, Col } from "antd";
import { edit_white_icon, delete_white_icon, edit_icon, delete_icon } from "../../assets/icons/icons";
import { showDeletePopup } from "../../helpers/helpers";
import { user } from "../../assets/images/images";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetMeetingsQuery } from "../../services/newMeeting";

const NetworkingSessionCard = ({
  networkingDetail,
  onEdit,
  onDelete,
  isAdmin,
  isOtherNetworking,
  filterCurrent,
  currentUserId,
  isUser,
  dontShowMeetings,
}) => {
  console.log(networkingDetail, "networkingDetail");

  console.log(isAdmin, "isUserisUserisUser");
  console.log(filterCurrent, "filterCurrentfilterCurrent");

  return (
    <div className="relative border border-primary rounded-xl p-7 space-y-5">
      <div className="flex items-center justify-between border-b border-gray-200 pb-8">
        <div className="flex justify-between items-center">
          <p className="text-xl md:text-3xl font-bold">
            {networkingDetail?.title}
          </p>
          <p className="text-2xl text-primary font-bold mt-1">Exhibition Hall</p>

        </div>
        <div className="flex justify-center items-center gap-5">
          <div className="bg-primary px-5 py-2 rounded">
            <p className="text-xl text-white font-semibold mt-1">{networkingDetail?.start_time} - {networkingDetail?.end_time}</p>
          </div>

          {isAdmin && (
            <div className="flex">
              <span
                className="cursor-pointer w-8"
                onClick={() => onEdit(networkingDetail)}
              >
                <img src={edit_icon} alt="icon" width={18} height={18} />
              </span>
              <span
                className="cursor-pointer"
                onClick={() => showDeletePopup(() => onDelete(networkingDetail))}
              >
                <img src={delete_icon} alt="icon" width={18} height={18} />
              </span>
            </div>
          )}
        </div>
      </div>
      <div
        className="flex flex-col justify-around"
        style={{ minHeight: 234 }}
      >
        <p className="text-primary text-center text-lg md:text-2xl font-bold">
          {isOtherNetworking
            ? "Other Networking Sessions"
            : "Networking Sessions"}
        </p>
        {networkingDetail?.slots?.map((v, i) => {
          // ... existing code ...

          const filterSLotsForPersonalized =
            filterCurrent && v?._id
              ? filterCurrent.filter(
                (item) =>
                  item?.slot?._id === v._id &&
                  (item?.sender?.user?._id === currentUserId ||
                    item?.receiver?.user?._id === currentUserId)
              )
              : [];

          const filterSLotsForAdmin =
            filterCurrent && v?._id
              ? filterCurrent.filter((item) => item?.slot?._id === v._id)
              : [];

          // ... existing code ...
          // const filterCurrentTime =

          return (
            <Row key={i} className="flex items-center">
              {currentUserId ? (
                <>
                  {filterSLotsForPersonalized?.map(
                    (item, index) =>
                      item?.sender?.user &&
                      item?.receiver?.user && (
                        <div className="w-full" key={index}>
                          {currentUserId && (
                            <Row key={i} className="flex items-center ">
                              <Col xl={5} lg={7} md={5} sm={7} xs={7}>
                                <p className="md:text-lg font-semibold">
                                  {v.title}{" "}
                                </p>
                                <span className="text-[12px] relative left-20">
                                  (Pre Arrange Meeting Area)
                                </span>
                              </Col>
                              <Col xl={13} lg={9} md={13} sm={9} xs={6}>
                                <div
                                  style={{
                                    border: "1px dashed rgba(0,0,0,.8)",
                                    borderBottomWidth: 1,
                                  }}
                                ></div>
                              </Col>
                              <Col xl={6} lg={8} md={6} sm={8} xs={11}>
                                <div className="flex flex-col items-start">
                                  <p className="text-xs font-medium text-end my-4 pl-2">
                                    {item.slot.startTime} | {item.slot.endTime}
                                  </p>
                                </div>
                              </Col>
                            </Row>
                          )}
                          <div className="flex justify-between mb-2 pr-2 w-[40%] m-[auto] items-center">
                            <div className="flex items-center">
                              <img
                                src={item?.sender?.user?.picture || user}
                                alt="user"
                                className="w-8 h-8 rounded-full object-contain"
                              />
                              <p className="text-xs font-medium ml-2">
                                {item?.sender?.user.first_name +
                                  " " +
                                  item?.sender?.user.last_name}
                              </p>
                            </div>

                            <div className="flex items-center justify-start w-20">
                              <img
                                src={item?.receiver?.user?.picture || user}
                                alt="user"
                                className="w-8 h-8 rounded-full object-contain"
                              />
                              <p className="text-xs font-medium ml-2 whitespace-nowrap">
                                {item?.receiver?.user.first_name +
                                  " " +
                                  item?.receiver?.user.last_name}
                              </p>
                            </div>
                            {isOtherNetworking ? (
                              <p className="text-xs font-medium ml-2 whitespace-nowrap bg-primary text-white p-2 rounded-md text-xl">
                                Table {index + 1}
                              </p>
                            ) : (
                              item?.sender?.user.metadata?.atendee_details
                                ?.table_number && (
                                <p className="text-xs font-medium ml-2 whitespace-nowrap bg-primary text-white p-2 rounded-md text-xl">
                                  Table{" "}
                                  {
                                    item?.sender?.user.metadata?.atendee_details
                                      ?.table_number
                                  }
                                </p>
                              )
                            )}
                          </div>
                        </div>
                      )
                  )}
                </>
              ) : (
                <>
                  <div className="w-full flex flex-col items-end flex-1 space-y-2 mb-3">
                    <Col xl={5} lg={7} md={5} sm={7} xs={7}>
                      <p className="md:text-lg font-semibold">{v.title} </p>
                      <span className="text-[12px] text-[#42572A] font-semibold">
                        Pre Arrange Meeting Area
                      </span>
                    </Col>
                    {/* <Col xl={13} lg={9} md={13} sm={9} xs={6}>
                      <div
                        style={{
                          border: "1px dashed rgba(0,0,0,.8)",
                          borderBottomWidth: 1,
                        }}
                      ></div>
                    </Col> */}
                    <Col xl={6} lg={8} md={6} sm={8} xs={11}>
                      <div className="flex flex-col items-start">
                        <div className="bg-primary py-2 px-3 rounded">
                          <p className="text-white font-semibold mt-1">{v.startTime} | {v.endTime}</p>
                        </div>
                        {/* <p className="text-xs font-medium">
                          {v.startTime} | {v.endTime}
                        </p> */}
                      </div>
                    </Col>
                  </div>
                  {filterSLotsForAdmin?.map(
                    (item, index) =>
                      !dontShowMeetings &&
                      item?.sender?.user &&
                      item?.receiver?.user && (
                        <div className="w-full border-y border-gray-200 flex justify-center items-center py-3 mb-3">
                          <div className="flex justify-between w-[60%] m-[auto]">
                            <div className="flex items-center gap-5 w-full">
                              <div className="w-[52px] h-[52px] rounded-full border border-primary">
                                <img
                                  src={item?.sender?.user?.picture || user}
                                  alt="user"
                                  className="w-full h-full object-cover rounded-full"
                                />
                              </div>
                              <p className="text-xs font-semibold italic text-[#42572A]">
                                {item?.sender?.user.first_name +
                                  " " +
                                  item?.sender?.user.last_name}
                              </p>
                            </div>

                            <div className="flex items-center justify-start w-full">
                              <div className="w-[52px] h-[52px] rounded-full border border-primary">
                                <img
                                  src={item?.receiver?.user?.picture || user}
                                  alt="user"
                                  className="w-full h-full rounded-full object-cover"
                                />
                              </div>
                              <p className="text-xs font-semibold italic ml-2 whitespace-nowrap">
                                {item?.receiver?.user.first_name +
                                  " " +
                                  item?.receiver?.user.last_name}
                              </p>
                            </div>
                          </div>
                        </div>
                      )
                  )}
                </>
              )}
            </Row>
          );
        })}
      </div>
    </div>
  );
};

export default NetworkingSessionCard;
