import React from 'react'

const Indicator = ({ status }) => {
    return (
        <div className="flex flex-col justify-center items-center gap-2">
            <div className={`h-3 w-3 rounded-full ${status === "Present" ? "bg-[#64E38A]" : "bg-[#F60004]"
                }`}></div>
            <p className="text-sm font-semibold">{status}</p>
        </div>
    )
}

export default Indicator
