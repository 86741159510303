import {
  break_icon,
  clock_icon,
  cup_icon,
  delete_white_icon,
  edit_white_icon,
} from "../../assets/icons/icons";
import { showDeletePopup } from "../../helpers/helpers";
import { FileDoneOutlined } from "@ant-design/icons";

const BreakCard = ({ breakDetail, onDelete, onEdit, isAdmin, isUser }) => {

  return (
    <div className="relative bg-primary rounded px-5">
      <div className="grid grid-cols-3  items-center justify-center h-20 static">
        <div className="flex justify-between items-center">
          <div className="flex gap-4">
            {/* <img src={cup_icon} alt="icon" /> */}
            <p className="text-xl md:text-2xl font-bold text-white text-center">
              {breakDetail?.title}{" "}
            </p>
          </div>
        </div>
        <div className="flex justify-center">
          <div className="w-[1.5px] h-[55px] bg-white"></div>
        </div>
        <div className="flex justify-end items-center gap-4">
          <img src={clock_icon} alt="icon" width={30} height={30} />
          <p className="text-white text-center text-2xl font-bold mt-1">
            {breakDetail?.start_time} - {breakDetail?.end_time}
          </p>
          {isAdmin && (
            <div className="flex">
              <span
                className="cursor-pointer w-8"
                onClick={() => onEdit(breakDetail)}
              >
                <img src={edit_white_icon} alt="" width={18} height={18} />
              </span>
              <span
                className="cursor-pointer"
                onClick={() => showDeletePopup(() => onDelete(breakDetail))}
              >
                <img src={delete_white_icon} alt="" width={18} height={18} />
              </span>
            </div>
          )}
        </div>
        {/* {isUser && (
          <div className="absolute right-10 flex">
            <span
              className="cursor-pointer"
              onClick={() =>
                showDeletePopup(() => onDelete(breakDetail.agenda_id))
              }
            >
              <img src={delete_white_icon} alt="" width={18} height={18} />
            </span>
          </div>
        )} */}
      </div>
      {/* <div
        style={{ height: 218 }}
        className="bg-white p-4 shadow-md shadow-gray-300 flex items-center justify-center"
      >
        {breakDetail.type == "registration" ? (
          <FileDoneOutlined style={{ fontSize: 180, color: "#e2b025" }} />
        ) : (
          <img src={break_icon} alt="" width={190} height={190} />
        )}
      </div> */}
    </div>
  );
};

export default BreakCard;
