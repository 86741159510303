import { Row, Col } from "antd";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { dashboard_white_icon, notification_icon } from "../../assets/icons/icons";
import { Container, StatsCard, ThemeTable } from "../../components/components";
import { Stats } from "../../config/data/data";
import { useLazyGetLogQuery } from "../../services/log";
import dayjs from "dayjs";
import { getCurrentToken } from "../../helpers/helpers";
import { ChevronDown } from 'lucide-react';
import { user_profile } from "../../assets/images/images";


const columns = [
  {
    title: "Type",
    dataIndex: "meta.log_type",
    key: "meta.log_type",
    width: "10%",
    render: (text, data) => (
      <p className="text-center font-medium">{data.meta.log_type || "---"}</p>
    ),
  },
  {
    title: "User",
    dataIndex: "meta.user_type",
    key: "meta.user_type",
    width: "10%",
    render: (text, data) => (
      <p className="text-center font-medium">{data.meta.user_type}</p>
    ),
  },
  {
    title: "Message",
    dataIndex: "message",
    key: "message",
    width: "70%",
    render: (text) => <p className="text-center font-medium">{text}</p>,
  },
  {
    title: "Date",
    dataIndex: "timestamp",
    key: "timestamp",
    width: "30%",
    render: (text) => (
      <p className="text-center font-medium">{new Date(text).toDateString()}</p>
    ),
  },
  {
    title: "Time",
    dataIndex: "timestamp",
    key: "timestamp",
    width: "30%",
    render: (text) => (
      <p className="text-center font-medium">
        {dayjs(text).format("hh:mm:ss A")}
      </p>
    ),
  },
];
const data = [
  {
    key: "1",
    notification: "AB Company had come in the Event",
    date: "1 - 2 - 2023",
  },
  {
    key: "2",
    notification: "AB Company had come in the Event",
    date: "1 - 2 - 2023",
  },
  {
    key: "3",
    notification: "AB Company had come in the Event",
    date: "1 - 2 - 2023",
  },
];

const Dashboard = () => {
  const [getLog, { isLoading }] = useLazyGetLogQuery({});
  const [logs, setLogs] = useState([]);
  const [viewLogs, setViewLogs] = useState(false)

  const getLogs = async () => {
    const logs = await getLog({
      currentPage: 1,
      resultPerPage: 10,
      type: "delegate,sponsor",
    });
    setLogs(logs?.data?.logs?.data || []);
  };
  useEffect(() => {
    getLogs();
  }, []);

  const userDetails = getCurrentToken()

  // console.log("User Details===>", userDetails)

  return (
    <Container
      selected={"1"}
      header={
        <div className="flex justify-between items-center border-b-2 border-[#E3E3E3] h-40">
          <div className="flex">

            <div className="bg-primary h-9 w-9 rounded-full flex justify-center items-center">
              <img src={dashboard_white_icon} width={17} height={17} alt="icon" />
            </div>
            <p className="ml-3 font-semibold text-2xl">Dashboard</p>
          </div>
          <div className="flex justify-center items-center space-x-6">
            <div className="h-16 w-16 rounded-2xl flex justify-center items-center bg-[#FFFAF1]">
              <img src={notification_icon} alt="icon" />
            </div>
            <div className="flex justify-center items-center space-x-6">
              <div className="w-[60px] h-[60px] rounded-2xl overflow-hidden">
                <img className="w-full h-full object-cover bg-center" src={user_profile} alt="user" />
              </div>
              <div className="flex space-x-5">
                <div>
                  <p className="font-semibold text-xl">User</p>
                  <p>Admin</p>
                </div>
                <ChevronDown strokeWidth={2.5} />
              </div>
            </div>
          </div>
        </div>
      }
    >
      <div className="flex justify-end items-center mx-4 mb-4">
        <NavLink to="/logs" className="font-medium bg-primary rounded-md p-3 text-white">
          View Logs
        </NavLink>
      </div>
      <Row className="gap-5 grid md:grid-cols-2 grid-cols-1 lg:grid-cols-3 w-full mb-10">
        {Stats.map((e, i) => {
          return (
            <Col key={i}>
              <StatsCard
                key={i}
                className={""}
                icon={e.icon}
                title={e.title}
                count={e.count}
              />
            </Col>
          );
        })}
      </Row>

      {/* <div>
        <div className="flex justify-between items-center mx-4 mb-4">
          <p className="text-2xl font-semibold">Logs</p>
          <NavLink to="/logs" className="font-medium">
            View all
          </NavLink>
        </div>
        <ThemeTable columns={columns} data={logs} />
      </div> */}
    </Container>
  );
};

export default Dashboard;
