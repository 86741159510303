import React from 'react'

const PendingMeetings = () => {
    const rows = [
        {
            no: "01",
            name: "Tests",
            company: "Analyzing essential features such as data capture,",
            attendance: ["Day 1", "Day 2"],
            pendingInvites: 20,
            inviteType: "green",
        },
        {
            no: "02",
            name: "Tests",
            company: "Analyzing essential features such as data capture,",
            attendance: ["Day 1", "Day 2"],
            pendingInvites: 10,
            inviteType: "green",
        },
        {
            no: "03",
            name: "Tests",
            company: "Analyzing essential features such as data capture,",
            attendance: ["Day 1", "Day 2"],
            pendingInvites: 5,
            inviteType: "red",
        },
    ];
    return (
        <div className="p-4">
            <table className="w-full border-collapse border border-gray-200">
                <thead>
                    <tr className="text-gray-500 text-left">
                        <th className="p-2">No</th>
                        <th className="p-2">ID</th>
                        <th className="p-2">Name</th>
                        <th className="p-2">Company Name</th>
                        <th className="p-2">Attendance</th>
                        <th className="p-2">Pending Invites</th>
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, index) => (
                        <tr
                            key={index}
                            className={`rounded-md mb-2   ${row.inviteType === "green" ? "bg-green-200" : "bg-red-200"
                                }`}
                        >
                            <td className="p-4">{row.no}</td>
                            <td className="p-4">
                                <img
                                    src="https://via.placeholder.com/50"
                                    alt="Profile"
                                    className="w-10 h-10 rounded-full"
                                />
                            </td>
                            <td className="p-4">{row.name}</td>
                            <td className="p-4">{row.company}</td>
                            <td className="p-4 flex gap-2">
                                {row.attendance.map((day, idx) => (
                                    <span
                                        key={idx}
                                        className={`text-sm font-medium flex items-center ${idx === 0 ? "text-green-600" : "text-red-600"
                                            }`}
                                    >
                                        <span className="w-2 h-2 rounded-full bg-current mr-1"></span>
                                        {day}
                                    </span>
                                ))}
                            </td>
                            <td className="p-4">
                                <span
                                    className={`px-4 py-1 rounded-full font-medium text-white ${row.inviteType === "green"
                                            ? "bg-green-500"
                                            : "bg-red-500"
                                        }`}
                                >
                                    {row.pendingInvites} Invites
                                </span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default PendingMeetings
